<template>
  <div>
    <b-container fluid>
      <div class="pagdiv">
        <span class="pageTitle">信件補發</span>
      </div>
      <b-row class="borderColor">
        <div style="width: 100%">
          <div style="margin: 10px">群組列表:</div>
          <div class="flexEmail">
            <date-picker
              style="width: 150px; margin-left: 5px"
              placeholder="寄送日期"
              v-model="sendTime"
              valueType="format"
              format="YYYY-MM-DD"
              :disabled-date="disabledDate"
              @change="getDailyreport(null)"
            ></date-picker>
            <input
              type="text"
              list="group"
              style="margin: 10px"
              v-model="selectDailyReport"
              @change="getDailyreport(true)"
            />
            <datalist id="group">
              <option
                v-for="(i, idx) in dailyReportArray"
                :key="idx"
                :value="i.group"
              >
                {{ i.factories }}
              </option>
            </datalist>
            <button
              class="deleteButton"
              style="color: gray; margin-left: 5px"
              @click="selectDailyReport = ''"
            >
              清空
            </button>
          </div>
          <div style="margin: 10px">收件人:</div>
          <div class="flexEmail">
            <div v-for="(i, idx) in sendmailArray" :key="idx">
              <input :value="i" style="margin: 10px; width: 250px" />
              <button
                class="deleteButton"
                style="color: gray"
                @click="emailSplice(idx)"
              >
                移除
              </button>
            </div>
          </div>
          <div style="margin: 10px">新增收件人:</div>
          <div class="flexEmail">
            <input
              style="margin: 10px; width: 250px"
              v-model="newEmailsValue"
            />
            <button
              class="confirmButton"
              style="margin: 5px; width: 70px"
              @click="emailPush"
            >
              新增
            </button>
          </div>

          <div style="margin: 10px">增加資訊:</div>
          <div class="flexEmail">
            <b-form-checkbox-group
              v-model="selectedPR"
              :options="selectPROptions"
              class="ml-3 mt-1"
            />
          </div>

          <div class="flexEmail">
            <!-- 內容: -->
            <div style="margin: 20px" v-if="dataLoading">
              <b-spinner big></b-spinner>
            </div>
            <div
              style="
                margin: 8px;
                padding: 5px;
                background: #ebebec;
                width: 500px;
              "
              v-for="(i, index) in dailyData"
              :key="index"
            >
              <table style="width: 100%">
                <tr>
                  <td>案場名稱</td>
                  <td>{{ i.reports.factoryTwName }}</td>
                </tr>
                <tr>
                  <td>案場設置容量</td>
                  <td>{{ dataHandle(i.reports.capacity) }} kWp</td>
                </tr>
                <tr>
                  <td>今日發電量</td>
                  <td>{{ dataHandle(i.reports.acpAll) }} kWh</td>
                </tr>
                <tr>
                  <td>總發電量</td>
                  <td>{{ dataHandle(i.reports.AC_kWh) }} kWh</td>
                </tr>
                <tr>
                  <td>今日累積日照量</td>
                  <td>{{ dataHandle(i.reports.IRR) }} kWh/m²</td>
                </tr>
                <tr>
                  <td>Specific Yield</td>
                  <td>{{ dataHandle(i.reports.peakWatt) }} kWh/kWp</td>
                </tr>
                <tr>
                  <td>今日 PR</td>
                  <td>{{ dataHandle(i.reports.PR) }} %</td>
                </tr>
                <template v-if="selectedPR?.length">
                  <tr>
                    <td>設計 PR 值</td>
                    <td>{{ dataHandle(i.reports.monDesignPR * 100) }} %</td>
                  </tr>
                  <tr>
                    <td>本月累積 PR 值</td>
                    <td>{{ dataHandle(i.reports.monPR * 100) }} %</td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
        <div style="margin: 0 auto">
          <button class="confirmButton" @click="sendmail">信件寄送</button>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import { BSpinner } from 'bootstrap-vue'
import {
  getDailyReportListApi,
  getDailyReportApi,
  getSendMailsApi
} from '../api'
import { DateTime } from 'luxon'
import { dbDataHandle } from '../store/function/common/index'
export default {
  components: {
    BSpinner
  },
  data () {
    return {
      dataLoading: false,
      newEmailsValue: '',
      selectDailyReport: '',
      dailyReportArray: [],
      dailySiteArray: [],
      sendTime: DateTime.local()
        .minus({ days: 1 })
        .setZone('Asia/Taipei')
        .toFormat('yyyy-MM-dd'),
      sendmailArray: [],
      selectedPR: [],
      selectPROptions: ['設計 PR 資訊']
    }
  },
  computed: {
    dailyData () {
      return this.dailySiteArray.map((obj) => {
        const { monPR, monDesignPR, ...reportsRest } = obj.reports
        return {
          factoryName: obj.factoryName,
          reports: Object.assign(
            reportsRest,
            this.selectedPR?.length && { monPR, monDesignPR }
          )
        }
      })
    }
  },
  methods: {
    async getDailyreportList () {
      const dailyreportlist = await getDailyReportListApi()
      if (dailyreportlist?.data?.success) {
        this.dailyReportArray = dailyreportlist.data.data
        this.selectDailyReport = dailyreportlist.data.data[0].group
      }
    },
    async getDailyreport (factoryChange) {
      if (
        this.dailyReportArray.find((x) => x.group === this.selectDailyReport)
      ) {
        this.dataLoading = true
        this.dailySiteArray = []
        const dailyReport = await getDailyReportApi(
          this.selectDailyReport,
          this.sendTime
        )
        if (dailyReport?.data?.success) {
          this.dailySiteArray = dailyReport.data.data.result
          if (factoryChange) this.sendmailArray = dailyReport.data.data.mails
          this.selectedPR = []
        }
        this.dataLoading = false
      }
    },
    async sendmail () {
      if (
        !this.dailyReportArray.find((x) => x.group === this.selectDailyReport)
      ) {
        this.$swal('請選擇群組')
        return
      }
      if (!this.sendmailArray?.length) return this.$swal('請新增收件人')
      const sendmail = await getSendMailsApi(
        this.selectDailyReport,
        this.sendTime,
        this.dailyData,
        this.sendmailArray
      )
      if (sendmail?.data?.success) {
        this.$swal('寄送成功')
      }
    },
    disabledDate (date) {
      const today = new Date()
      today.setDate(today.getDate() - 1)
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime())
    },
    emailPush () {
      if (
        this.newEmailsValue !== '' &&
        this.newEmailsValue.indexOf('@') !== -1
      ) {
        this.sendmailArray.push(this.newEmailsValue)
      } else {
        this.$swal('請填寫正確emails格式')
      }
    },
    emailSplice (idx) {
      this.sendmailArray.splice(idx, 1)
    },
    dataHandle (val) {
      return dbDataHandle(val)
    }
  },
  async created () {
    await this.getDailyreportList()
    this.getDailyreport(true)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/global.scss";
@import "../assets/scss/customVariables.scss";

table td {
  border: 1px solid rgb(175, 170, 170);
  padding: 5px;
  color: #333;
}

.flexEmail {
  display: flex;
  align-items: center;
  margin: 10px;
  flex-wrap: wrap;
}
</style>
